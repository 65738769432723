import { getPropertyOffices, updatePropertyOffices } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    type: 'autocomplete',
    autoCompleteType: 'office',
    multiple: true,
    name: 'offices',
    label: 'Kantoor',
    placeholder: 'Selecteer kantoren',
    validation: 'required'
  }
]

const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyOffices(propertyId)
      const offices = response.data?.results
      return { offices }
    } catch (error) {
      errorModal('Fout bij laden van kantoren, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const { offices } = data
      const office_id_list = offices.map(office => office.id)
      const response = await updatePropertyOffices(propertyId, { office_id_list })
      successModal('Kantoren zijn opgeslagen.')
      return response
    } catch (error) {
      let errorMessage = 'Fout bij opslaan van kantoren, probeer het opnieuw.'
      if (error.response?.data?.code === 'PORTAL_PUBLICATIONS_EXIST') errorMessage = 'Kan kantoren niet wijzigen omdat er actieve portaalpublicaties zijn.'
      errorModal(errorMessage)
      throw error
    }
  }
}

export default {
  heading: 'Kantoren',
  schema,
  actions
}
